import { FuseNavigationItem } from '@fuse/components/navigation';
import { oAuthNavigation } from './apps/o-auth/o-auth.navigation';
import { iamNavigation } from './apps/iam/iam.navigation';
import { scoutsNavigation } from './apps/scouts/scouts.navigation';
import { commonNavigation } from './apps/common/common.navigation';
import { queueManagerNavigation } from './apps/queue-manager/queue-manager.navigation';
import { messageNavigation } from './apps/message/message.navigation';

export const adminNavigation: FuseNavigationItem[] = [
    scoutsNavigation,
    oAuthNavigation,
    iamNavigation,
    commonNavigation,
    queueManagerNavigation,
    messageNavigation
];
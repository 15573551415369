<div
    mat-dialog-title
    class="dialog-header"
>
    <p class="title">
        <mat-icon
            [svgIcon]="data.svgIcon"
        >
            {{ data.icon }}
        </mat-icon>
        <span>{{ data.title }}</span>
    </p>

    <button
        mat-dialog-close
        mat-icon-button
        class="absolute top-2 right-2"
    >
        <mat-icon>close</mat-icon>
    </button>
</div>
<div
    mat-dialog-content
    class="dialog-content flex flex-col p-8 pt-2 h-full max-h-full"
>
    <div *ngIf="data.content">
        <ng-container
            *ngTemplateOutlet="data.content?.templateRef; context: { $implicit: dialogRef }"
        >
        </ng-container>
    </div>
</div>
<div
    mat-dialog-actions
    class="dialog-actions p-0 min-h-0"
>
    <div *ngIf="data.footerActions">
        <ng-container
            class="block"
            *ngTemplateOutlet="data.footerActions?.templateRef; context: { $implicit: dialogRef }"
        >
        </ng-container>
    </div>
</div>
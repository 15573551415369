export const environment = {
    production: false,
    debug     : true,
    api       : {
        graphql: 'https://scouts-dev-api.beopen.cloud/graphql',
        rest   : 'https://scouts-dev-api.beopen.cloud',
    },
    lang: {
        base    : 'es',
        fallback: 'es',
        langs   : ['es','en'],
    },
    oAuth: {
        applicationCode  : 'scouts',
        applicationSecret: 'y+yUuzjqI-fLMxz%tjuV3LjGIyocY8AblR3spCtNObYTZWHSHgCxNlXH[awc}Q4DwP}+{JDa',
    },
};

<div mat-dialog-title class="dialog-header">
    <p class="title">
        <mat-icon>filter_list</mat-icon>
        <span>{{ 'filter' | gridTranslate:'message':gridId | async }}</span>
    </p>

    <button mat-dialog-close mat-icon-button class="absolute top-2 right-2">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content class="dialog-content flex flex-col min-h-[50vh]">
	<mat-form-field
		appearance="outline"
		class="w-full cursor-pointer my-4"
		data-input-type="filter-field-control"
	>
		<mat-label>{{ 'field' | gridTranslate:'message':gridId | async }}</mat-label>
		<input
			type="text"
			aria-label="String"
			matInput
			class="cursor-pointer disabled:cursor-not-allowed"
			[placeholder]="'pleaseSelectField' | gridTranslate:'message':gridId | async"
			[formControl]="searchFieldNameControl"
			[matAutocomplete]="auto"
		>
		<mat-autocomplete
			autoActiveFirstOption
			#auto="matAutocomplete"
			(optionSelected)="handleFieldSelectionChange($event)"
		>
            @for (item of filteredColumnsConfig | async; track item.field)
            {
                <mat-option [value]="item">
                    {{ item.field | gridTranslate:'column':gridId | async }}
                </mat-option>
            }
		</mat-autocomplete>
	</mat-form-field>

	<div class="flex flex-col">
		<!-- column picker -->
		<div class="flex-grow">
			<form
                id="filterGridDialog"
				class="w-full grid grid-cols-12 gap-2 items-center"
				[formGroup]="containerForm"
			>
				<!-- generated input fields -->
                <ng-container formArrayName="formColumnFilter">
                    @for (formFilter of formColumnFilter.controls; track formFilter.value.id; let idx = $index)
                    {
                        <ng-container [formGroup]="formFilter">
                            <span class="text-sm col-span-12">
                                {{ formFilter.get('field').value | gridTranslate:'column':gridId  | async }}
                            </span>

                            <!-- operator selector -->
                            <mat-form-field
                                appearance="outline"
                                class="col-span-4"
                                tiny
                            >
                                <mat-label>{{ 'operator' | gridTranslate:'message':gridId | async }}</mat-label>
                                <mat-select formControlName="operator">
                                    @for (filterOperator of filterOperators | filterOperators:formFilter.get('searchComponent').value; track filterOperator.translation)
                                    {
                                        <mat-option [value]="filterOperator.operator">
                                            {{ filterOperator.translation | gridTranslate:'operator':gridId | async }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>

                            @switch (formFilter.get('searchComponent').value)
                            {
                                <!-- string -->
                                @case (searchComponentType.TEXT)
                                {
                                    <mat-form-field
                                        appearance="outline"
                                        class="col-span-7 relative"
                                        tiny
                                    >
                                        <mat-label>{{ 'value' | gridTranslate:'message':gridId | async }}</mat-label>
                                        <input
                                            autocomplete="off"
                                            formControlName="value"
                                            matInput
                                        >
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </mat-form-field>
                                }

                                <!-- enum -->
                                @case (searchComponentType.SELECT)
                                {
                                    <mat-form-field
                                        appearance="outline"
                                        class="col-span-7 relative"
                                        tiny
                                    >
                                        <mat-label>{{ 'value' | gridTranslate:'message':gridId | async }}</mat-label>
                                        <mat-select formControlName="value">
                                            @for (filterValue of originColumnsConfig | getGridFilterValues:formFilter.get('field').value; track filterValue.key)
                                            {
                                                <mat-option [value]="filterValue.key">
                                                    @if (data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value)
                                                    {
                                                        <ng-container
                                                            *ngTemplateOutlet="(data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value).templateRef; context: { $implicit: filterValue.value }"
                                                        >
                                                        </ng-container>
                                                    }
                                                    @else
                                                    {
                                                        {{ filterValue.value }}
                                                    }
                                                </mat-option>
                                            }
                                        </mat-select>
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </mat-form-field>
                                }

                                <!-- array -->
                                @case (searchComponentType.MULTIPLE_SELECT)
                                {
                                    <mat-form-field
                                        appearance="outline"
                                        class="col-span-7 relative"
                                        tiny
                                    >
                                        <mat-label>{{ 'value' | gridTranslate:'message':gridId | async }}</mat-label>
                                        <mat-select
                                            formControlName="value"
                                            multiple
                                        >
                                            @for (filterValue of originColumnsConfig | getGridFilterValues:formFilter.get('field').value; track filterValue.key)
                                            {
                                                <mat-option [value]="filterValue.key">
                                                    @if (data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value)
                                                    {
                                                        <ng-container
                                                            *ngTemplateOutlet="(data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value).templateRef; context: { $implicit: filterValue.value }"
                                                        >
                                                        </ng-container>
                                                    }
                                                    @else
                                                    {
                                                        {{ filterValue.value }}
                                                    }
                                                </mat-option>
                                            }
                                        </mat-select>
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </mat-form-field>
                                }

                                @case (searchComponentType.ASYNC_MULTIPLE_SELECT)
                                {
                                    <mat-form-field
                                        appearance="outline"
                                        class="col-span-7 relative"
                                        tiny
                                    >
                                        <mat-label>{{ 'value' | gridTranslate:'message':gridId | async }}</mat-label>
                                        <mat-select
                                            formControlName="value"
                                            multiple
                                            auScrollEnd
                                            (scrollEnd)="
                                                getAsyncMatSelectSearchColumnConfigFunction(
                                                    originColumnsConfig,
                                                    formFilter.get('field').value
                                                )
                                                .manageAsyncMatSelectSearch({
                                                    asyncMatSelectSearchState: getAsyncMatSelectSearchColumnConfigFunction(
                                                            originColumnsConfig,
                                                            formFilter.get('field').value
                                                        ).asyncMatSelectSearchState,
                                                    isFromScrollEndEvent: true,
                                                })
                                            "
                                        >
                                            <mat-option>
                                                <ngx-mat-select-search
                                                    [formControl]="(originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.itemFilterCtrl"
                                                    [placeholderLabel]="('find' | gridTranslate:'message':gridId | async) + ' ' + (formFilter.get('field').value | gridTranslate:'column':gridId  | async | lowercase)"
                                                >
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            @if ((originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.selectedItems().size > 0)
                                            {
                                                <mat-optgroup [label]="'selectedOptions' | gridTranslate:'message':gridId | async">
                                                    @for (item of (originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.selectedItems(); track item.id)
                                                    {
                                                        <mat-option
                                                            [value]="item.id"
                                                            auMatSelectAddSelectedDirective
                                                            [object]="item"
                                                            [selectedItems]="(originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.selectedItems"
                                                        >
                                                            @if (data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value)
                                                            {
                                                                <ng-container
                                                                    *ngTemplateOutlet="(data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value).templateRef; context: { $implicit: item }"
                                                                >
                                                                </ng-container>
                                                            }
                                                            @else
                                                            {
                                                                {{ item.name }}
                                                            }
                                                        </mat-option>
                                                    }
                                                </mat-optgroup>
                                                <mat-divider></mat-divider>
                                            }
                                            @for (item of (originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.filteredItems(); track item.id)
                                            {
                                                @if (!(originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.selectedItems().has(item))
                                                {
                                                    <mat-option
                                                        [value]="item.id"
                                                        [disabled]="item.id === null"
                                                        auMatSelectAddSelectedDirective
                                                        [object]="item"
                                                        [selectedItems]="(originColumnsConfig | getAsyncMatSelectSearchColumnConfig:formFilter.get('field').value).asyncMatSelectSearchState.selectedItems"
                                                    >
                                                        @if (item.id === null)
                                                        {
                                                            {{ 'noResultsFound' | gridTranslate:'message':gridId | async }}
                                                        }
                                                        @else if (data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value)
                                                        {
                                                            <ng-container
                                                                *ngTemplateOutlet="(data.gridFiltersDialogValuesTemplate | getGridFilterValue:formFilter.get('field').value).templateRef; context: { $implicit: item }"
                                                            >
                                                            </ng-container>
                                                        }
                                                        @else
                                                        {
                                                            {{ item.name }}
                                                        }
                                                    </mat-option>
                                                }
                                            }
                                        </mat-select>
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </mat-form-field>
                                }

                                <!-- number -->
                                @case (searchComponentType.NUMBER)
                                {
                                    <mat-form-field
                                        appearance="outline"
                                        class="col-span-7 relative"
                                        tiny
                                    >
                                        <mat-label>{{ 'value' | gridTranslate:'message':gridId | async }}</mat-label>
                                        <input
                                            type="number"
                                            autocomplete="off"
                                            formControlName="value"
                                            matInput
                                        >
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </mat-form-field>
                                }

                                <!-- boolean -->
                                @case (searchComponentType.CHECKBOX)
                                {
                                    <div class="col-span-7 relative pl-12">
                                        <mat-checkbox formControlName="value">
                                            {{ 'value' | gridTranslate:'message':gridId | async }}
                                        </mat-checkbox>
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </div>
                                }

                                <!-- date -->
                                @case (searchComponentType.DATEPICKER)
                                {
                                    <mat-form-field
                                        appearance="outline"
                                        class="au-datepicker col-span-7 relative"
                                        tiny
                                    >
                                        <mat-label>{{ 'value' | gridTranslate:'message':gridId | async }}</mat-label>
                                        <input
                                            matInput
                                            autocomplete="off"
                                            formControlName="value"
                                            auDatepickerSqlFormat
                                            [matDatepicker]="picker"
                                        >
                                        <mat-datepicker-toggle
                                            matIconSuffix
                                            [for]="picker"
                                        >
                                            <mat-icon
                                                class="icon-size-5"
                                                matDatepickerToggleIcon
                                            >
                                                today
                                            </mat-icon>
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        @if (arrayColumnFilters | hasRenderOutbox:formFilter.value)
                                        {
                                            <div class="outbox--datepicker">
                                                <span>{{ formFilter.get('type').value | getConcatOperator | gridTranslate:'message':gridId | async }}</span>
                                            </div>
                                        }
                                    </mat-form-field>
                                }
                            }
                            <button
                                mat-icon-button
                                class="col-span-1 mb-5"
                                type="button"
                                (click)="handleDeleteFilter(idx)"
                            >
                                <mat-icon>highlight_off</mat-icon>
                            </button>
                        </ng-container>
                    }
                </ng-container>
			</form>
		</div>
	</div>
</div>

<!-- confirmation buttons -->
<mat-dialog-actions class="grid grid-cols-2 gap-2">
    <button
        mat-flat-button
        [mat-dialog-close]="closeDialogAndRemoveFilters()"
        (click)="resetFilters()"
    >
        {{ 'clearFilters' | gridTranslate:'message':gridId | async }}
    </button>
    <button
        type="submit"
        color="primary"
        form="filterGridDialog"
        mat-flat-button
        [mat-dialog-close]="closeDialogAndSetFilters()"
        [disabled]="!containerForm.valid"
    >
        {{ 'search' | gridTranslate:'message':gridId | async }}
    </button>
</mat-dialog-actions>

<button
    (click)="isVisible = !isVisible"
    class="mat-icon-button cdk-focused cdk-mouse-focused"
    mat-icon-button
    matRipple
    matRippleCentered="true"
    matRipple
    [attr.tabindex]="tabindex ? tabindex : null"
    type="button"
>
        <mat-icon>{{ isVisible ? "visibility" : "visibility_off" }}</mat-icon>
</button>
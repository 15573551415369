<div class="relative">
    <p class="absolute top-0 -left-1 bg-white px-2 pt-1 origin-center -translate-y-1/2 scale-75 text-base z-999 pointer-events-none">
        <span class="opacity-60">{{ label }}</span>
    </p>

    <div class="rounded-md border focus-within:border-2 p-0.5 focus-within:p-px transition-all">
        <!-- main -->
        <div
            class="flex flex-auto pt-0 px-2 overflow-y-auto"
            cdkScrollable
        >
            <!-- grid -->
            <au-grid
                [id]="id"
                [columnsConfig]="columnsConfig"
                [gridData]="gridData"
                [gridState]="gridState"
                [originColumnsConfig]="originColumnsConfig"
                [hasSearch]="hasSearch"
                [hasFilterButton]="hasFilterButton"
                (action)="action.emit($event)"
                (columnFiltersChange)="columnFiltersChange.emit($event)"
                (columnsConfigChange)="columnsConfigChange.emit($event)"
                (search)="search.emit($event)"
                (stateChange)="stateChange.emit($event)"
            >
                <ng-template
                    auGridCustomHeaderTemplate
                    position="left"
                >
                    <!-- we can defined buttons from component parent across template directive -->
                    <ng-container *ngIf="gridCustomButtonsHeaderDialogTemplate">
                        <ng-container *ngTemplateOutlet="gridCustomButtonsHeaderDialogTemplate.templateRef; context: {}">
                        </ng-container>
                    </ng-container>

                    <!-- we can use default button -->
                    <ng-container *ngIf="!gridCustomButtonsHeaderDialogTemplate">
                        Deprecated default button, implement auGridCustomButtonsHeaderDialogTemplate
                    </ng-container>
                </ng-template>

                <!-- proxy to pass GridElementsManagerCellValueTemplateDirective -->
                <ng-container *ngFor="let gridElementManagerCellValue of gridElementsManagerCellValueTemplate">
                    <ng-template
                        auGridCellValueTemplate
                        let-value
                        let-columnConfig="columnConfig"
                        let-row="row"
                        [field]="gridElementManagerCellValue.field"
                    >
                        <ng-container *ngTemplateOutlet="gridElementManagerCellValue.templateRef; context: { $implicit: value, columnConfig, row }">
                        </ng-container>
                    </ng-template>
                </ng-container>

            </au-grid>
            <!-- / grid -->
        </div>
        <!-- / main -->
    </div>
</div>